import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroTwo'
import HomeNews from '../components/homeNews'
import ColumnOverlayFocus from '../components/columnOverlayFocus'
import ColumnAlternating from '../components/columnAlternatingStyled'
import Card from '../components/card'
import LogoCarousel from '../components/logoCarousel'

const Index = ({ data, location }) => {
  const post = data.markdownRemark
  
  return (
    <Layout>
      <div id="intro-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
        />

        <Hero
          class="home-110"
          foreimage={post.frontmatter.image.childImageSharp.gatsbyImageData}
          forealt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctaslug="#scrollto"
          ctatext="How we can help"
          ctaslug2="/contact/"
          ctatext2="Get in touch"
          type="home"
        />
      </div>

      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="#FFFFFF" ></path>
          </svg>
        </div>
      </div>

      <main id="main">
        <MDBContainer className="pb-100">
          <ColumnOverlayFocus focus={post.frontmatter.focus} />
        </MDBContainer>

        <>  
        {post.frontmatter.section &&
          <section className="bg-white-grey-gradient">
            <MDBAnimation type="fadeIn" duration="1587ms">
            <MDBContainer>
              <MDBRow>
                <MDBCol>
              <h2 className="font-alt font-w-700 text-center letter-spacing-1 mt-4 mt-xl-0 mb-5 title-xs-medium title-large" >
              Explore Macro 4 solutions and services
              </h2>
              </MDBCol>
              </MDBRow>
            </MDBContainer>
              <MDBContainer>
                <div className="about-overview pt-5">
                  {post.frontmatter.section.map((sections, index) => {
                    return (
                      <ColumnAlternating
                        key={index}
                        title={sections.title}
                        subtitle={sections.subtitle}
                        subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                        description={sections.description}
                        image={sections.image.childImageSharp.gatsbyImageData}
                        alt={sections.alttext}
                        placement={sections.placement}
                        titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                        link={ sections.btnlink == 'capabilities' ? '/solutions/' + sections.btnlink : '/' + sections.btnlink }
                        colour={sections.btncolour}
                      />
                    )
                  })}
                </div>
              </MDBContainer>
            </MDBAnimation>
          </section>
        } 
        </>

        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <h2 className="font-alt font-w-700 text-center letter-spacing-1 mt-4 mt-xl-0 mb-5 title-xs-medium title-large" >
                  Learn how business leaders have transformed with us
                  </h2>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <LogoCarousel />
          </MDBAnimation>  
        </section>

        <section className="bg-gray-light">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <h2 className="font-alt font-w-700 text-center letter-spacing-1 mt-4 mt-xl-0 mb-5 title-xs-medium title-large">
                {post.frontmatter.insights.title}
              </h2>
              {post.frontmatter.insights.items && (
                <MDBRow className="pt-4">
                  <>
                    {post.frontmatter.insights.items.map((item, index) => {
                      return (
                        <Card
                          key={index}
                          collg="4"
                          colmd="6"
                          height="11.5rem"
                          title={item.title}
                          subtitle={item.subtitle}
                          description={item.description}
                          image={item.image.childImageSharp.gatsbyImageData}
                          alttext={item.alttext}
                          placement={item.placement}
                          link={item.link}
                          descriptionClass="text-card-small"
                        />
                      )
                    })}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>


        <HomeNews />
      </main>
    </Layout>
  )
}
export default Index 

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { name: { eq: "index" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, width: 540, quality: 90) 
          }
        }
        alttext
        focus {
          title
          subtitle
          items {
            title
            subtitle
            image {
              childImageSharp {
                gatsbyImageData(width: 90, quality: 90) 
              }
            }
            alttext
            link
          }
        }
        section {
          title
          subtitle
          subtitlecolour
          description
          image {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, quality: 90)
            }
          }
          alttext
          placement
          btnlink
          btncolour
        }
        insights {
          title
          items {
            title
            subtitle
            description
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, quality: 90)
              }
            }
            placement
            alttext
            link
          }
        }
      }
    }
  }
`