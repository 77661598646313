import React from "react";
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBRow, MDBCol, MDBCard } from "mdbreact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

const MultiCarouselPage = () => {
  return (
    <MDBContainer>
      <MDBCarousel activeItem={1} length={2} slide={true} showControls={false} showIndicators={true} interval={5000} multiItem>
        <MDBCarouselInner>
          <MDBRow>
            <MDBCarouselItem itemId="1">
              <MDBCol md="3">
                <a href="/assets/customer-success-story-uk-greetings.pdf" target="_blank">
                  <MDBCard className="mb-2 no-box-shadow">
                    <StaticImage src="../images/carousel-uk-greetings-logo.png" className="img-fluid" alt="UK Greetings customer logo" />
                  </MDBCard>
                </a>
              </MDBCol>
              <MDBCol md="3">
              <a href="/assets/customer-success-story-severn-trent-water.pdf" target="_blank">
                <MDBCard className="mb-2 no-box-shadow">
                  <StaticImage src="../images/carousel-severn-trent-logo.png" className="img-fluid" alt="Severn Trent Water logo" />                  
                </MDBCard>
              </a>
              </MDBCol>
              <MDBCol md="3">
                <a href="/assets/customer-success-story-orio.pdf" target="_blank">
                  <MDBCard className="mb-2 no-box-shadow">
                    <StaticImage src="../images/carousel-orio-logo.png" className="img-fluid" alt="Orio customer logo" />
                  </MDBCard>
                </a>
              </MDBCol>
              <MDBCol md="3">
              <a href="/assets/customer-success-story-carewatch.pdf" target="_blank">
                <MDBCard className="mb-2 no-box-shadow">
                  <StaticImage src="../images/carousel-carewatch-logo.png" className="img-fluid" alt="Carewatch customer logo" />
                </MDBCard>
              </a>
              </MDBCol>              
            </MDBCarouselItem>

            <MDBCarouselItem itemId="2">
              <MDBCol md="3">
              <Link to="/paperless-billing-at-allstar/" target="_blank">
                <MDBCard className="mb-2 no-box-shadow">
                  <StaticImage src="../images/carousel-allstar-logo.png" className="img-fluid" alt="Allstar customer logo" />
                </MDBCard>
              </Link>
              </MDBCol>
              <MDBCol md="3">
              <a href="/assets/customer-success-story-tnt.pdf" target="_blank">
                <MDBCard className="mb-2 no-box-shadow">
                  <StaticImage src="../images/carousel-tnt-logo.png" className="img-fluid" alt="TNT customer logo" />
                </MDBCard>
              </a>
              </MDBCol>
              <MDBCol md="3">
              <a href="/assets/customer-success-story-tesco-plc.pdf" target="_blank">
                <MDBCard className="mb-2 no-box-shadow">
                  <StaticImage src="../images/carousel-tesco-logo.png" className="img-fluid" alt="Tesco customer logo" />                  
                </MDBCard>
              </a>
              </MDBCol>                          
            </MDBCarouselItem>

          </MDBRow>
        </MDBCarouselInner>   
      </MDBCarousel>

      <MDBRow className="text-center">
        <MDBCol>
          <Link to="/resources/customer-success-stories/" className="nav-link btn btn-mdb-color btn-sm-block mt-5">
              <FontAwesomeIcon icon={faChevronRight} className="mr-2" />More customer success stories
          </Link>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default MultiCarouselPage;